import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutAscpComponent } from './pages/about-ascp/about-ascp.component';
import { AboutMarkLeesComponent } from './pages/about-mark-lees/about-mark-lees.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { FaqComponent } from './pages/faq/faq.component';
import { AddToHomeScreenComponent } from './pages/add-to-home-screen/add-to-home-screen.component';

import { ConditionsComponent } from './pages/conditions/conditions.component';
import { ContentListComponent } from './pages/content/content-list/content-list.component';
import { ContentSavedComponent } from './pages/content/content-saved/content-saved.component';
import { ContentSearchBoxComponent } from './pages/content/content-search/content-search-box/content-search-box.component';
import { ContentSearchComponent } from './pages/content/content-search/content-search.component';
import { IngredientsComponent } from './pages/ingredients/ingredients.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'search', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: {} },
  {
    path: 'search',
    component: ContentSearchComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'contentnode' },
    children: [
      { path: '', component: ContentSearchBoxComponent, data: { animation: 'contentnode' } },
      { path: 'list/:type', component: ContentListComponent, data: { animation: 'contentnode' } },
    ]
  },
  { path: 'about-ascp', component: AboutAscpComponent },
  { path: 'about-mark-lees', component: AboutMarkLeesComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'using-skinpro', component: FaqComponent },
  { path: 'add-to-home-screen', component: AddToHomeScreenComponent },
  { path: 'conditions/:name', component: ConditionsComponent, canActivate: [AuthGuardService], data: { animation: '' } },
  { path: 'ingredients/:name', component: IngredientsComponent, canActivate: [AuthGuardService], data: { animation: '' } },
  { path: 'recent', component: ContentSavedComponent, canActivate: [AuthGuardService], data: { title: 'Recent Conditions & Ingredients', type: 'recent' } },
  { path: 'favorite', component: ContentSavedComponent, canActivate: [AuthGuardService], data: { title: 'Saved Conditions & Ingredients', type: 'favorite' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
