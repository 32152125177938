import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppAccordionComponent } from './components/accordion/accordion.component';
import { ContentShortComponent } from './components/content-short/content-short.component';
import { DefinitionComponent } from './components/definition/definition.component';
import { FavoriteButtonComponent } from './components/favorite-button/favorite-button.component';
import { ImageComponent } from './components/image/image.component';
import { ModalErrorComponent } from './components/modal-error/modal-error.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TitleComponent } from './components/title/title.component';
import { tokenGetter } from './helpers/tokenGetter';
import { HeaderNavComponent } from './layout/header/header-nav/header-nav.component';
import { HeaderComponent } from './layout/header/header.component';
import { LoadingComponent } from './layout/loading/loading.component';
import { MenuDropdownComponent } from './layout/menu-dropdown/menu-dropdown.component';
import { NavBottomComponent } from './layout/nav-bottom/nav-bottom.component';
import { ConditionsComponent } from './pages/conditions/conditions.component';
import { ContentListComponent } from './pages/content/content-list/content-list.component';
import { ContentSavedComponent } from './pages/content/content-saved/content-saved.component';
import { ContentSearchBoxComponent } from './pages/content/content-search/content-search-box/content-search-box.component';
import { ContentSearchComponent } from './pages/content/content-search/content-search.component';
import { IngredientsComponent } from './pages/ingredients/ingredients.component';
import { LoginFormComponent } from './pages/login/login-form/login-form.component';
import { LoginComponent } from './pages/login/login.component';
import { JwtInterceptor } from './services/jwt.interceptor';
import { FaqComponent } from './pages/faq/faq.component';
import { AboutAscpComponent } from './pages/about-ascp/about-ascp.component';
import { AboutMarkLeesComponent } from './pages/about-mark-lees/about-mark-lees.component';
import { AboutSkinProComponent } from './pages/about-skinpro/about-skinpro.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AddToHomeScreenComponent } from './pages/add-to-home-screen/add-to-home-screen.component';
import { NavSearchBarComponent } from './components/nav-search-bar/nav-search-bar.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavBottomComponent,
    MenuDropdownComponent,
    HeaderNavComponent,
    ConditionsComponent,
    IngredientsComponent,
    TitleComponent,
    DefinitionComponent,
    ImageComponent,
    TabsComponent,
    AppAccordionComponent,
    ContentListComponent,
    ContentSearchComponent,
    SearchBoxComponent,
    ContentShortComponent,
    LoginComponent,
    LoginFormComponent,
    ModalErrorComponent,
    LoadingComponent,
    FavoriteButtonComponent,
    ContentSearchBoxComponent,
    ContentSavedComponent,
    FaqComponent,
    AboutAscpComponent,
    AboutMarkLeesComponent,
    AboutSkinProComponent,
    AddToHomeScreenComponent,
    NavSearchBarComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    CollapseModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost', 'ascpskincare.com', 'skinpro-dev.ascpskincare.com', 'skinpro.lt-sham.dev.cc', 'umbraco-api.azurewebsites.net']
      }
    }),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      autoDismiss: true
    }),
    TypeaheadModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(httpClient: HttpClient) {
  }
}
