import { IContentAlias } from "./content.interface";
import { Content } from "./content.model";

export class Ingredient extends Content {

  public readonly Type = 'ingredient';

  constructor(source?: IContentAlias) {
    super(source);
  }
}
