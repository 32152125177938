import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Condition } from '../models/condition.model';
import { IContentAlias } from '../models/content.interface';
import { Environment, IEnvironment } from '../models/environment';
import { ContentService } from './content.service';

@Injectable({
  providedIn: 'root'
})
export class ConditionService extends ContentService<Condition> implements OnDestroy {

  public constructor(
    @Inject(Environment) environment: IEnvironment,
    http: HttpClient,
    modalService: BsModalService) {
    
    super('conditions', environment, http, modalService);
  }

  public ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  protected Instantiate(source: IContentAlias): Condition {
    return new Condition(source);
  }

}
