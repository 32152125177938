<ng-container dropdown [placement]="Placement + ' right'" [dropup]="Placement === 'top'">

  <button [ngClass]="ButtonClass" class="btn hideMobi me-lg-0 me-xl-3 align-top px-md-1" type="button" routerLink="/favorite">
    <fa-icon class="h4 menu_icon pe-md-1" [icon]="faBookmarkRegular"></fa-icon>
    <span class="btn_text_header ps-lg-1">Saved</span>
  </button>
  <button [ngClass]="ButtonClass" class="btn hideMobi me-lg-0 me-xl-3 align-top px-md-1 " type="button" routerLink="/recent">
    <fa-icon class="h4 menu_icon pe-md-1" [icon]="faUndo"></fa-icon>
    <span class="btn_text_header ps-lg-1">Recent</span>
  </button>

  <button dropdownToggle class="btn" [ngClass]="ButtonClass" type="button" aria-controls="dropdown-basic">
    <fa-icon class="menu_icon h4" [icon]="faBars"></fa-icon>
    <span *ngIf="ButtonText" class="btn_text" [innerText]="ButtonText"></span>
  </button>

  <nav *dropdownMenu class="dropdown-menu dropdown-menu-right py-0" role="menu" aria-label="Navigation Menu">
    <div class="list-group">
      <a class="dropdown-item list-group-item hideMobi" [routerLink]="['/']">Home</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/about-ascp']">About ASCP</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/about-mark-lees']">About Mark Lees</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/using-skinpro']">Using SkinPro</a>
      <a class="dropdown-item list-group-item" href="https://www.ascpskincare.com/members" target="_blank">ASCPskincare.com</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/add-to-home-screen']">Add App to Home Screen</a>
    </div>
  </nav>
  
</ng-container>
