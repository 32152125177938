<ng-container>
  <div class="container-lg">
    <div class="row justify-content-end mx-0">
      <div class="col-xs-12 col-md-6 d-none d-md-block pipette">
        <img src="{{ deployUrl }}assets/images/pink-pipette.png">
      </div>
      <div class="col-md-6 col-sm-12">
        <div *ngIf="Loading$ | async; else loading">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="container-lg">
    <main>
      <div class="row justify-content-end">
        <div class="col-12 col-md-12">
          <div class="jumbotron mt-4">
            <div class="spinner-border" role="status" aria-hidden="true"></div><strong>Loading...</strong>
            <p>please reload if you see this longer than several seconds</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</ng-template>
