import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AllContent } from 'src/app/services/helpers';
import { ConditionService } from 'src/app/services/condition.service';
import { IngredientService } from 'src/app/services/ingredient.service';

@Component({
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent implements OnInit {

  private filter$: Subject<string[]> = new BehaviorSubject<string[]>([]);
  public Content$: Observable<AllContent[]> = new Observable<AllContent[]>();

  @Input()
  public type: string = '';

  constructor(
    private route: ActivatedRoute,
    private ingredientService: IngredientService, 
    private conditionService: ConditionService) {
  }

  ngOnInit(): void {
    this.type = this.route.snapshot.params["type"];

    if (this.type === 'ingredients') {
      this.Content$ = combineLatest([this.filter$, this.ingredientService.Content$]).pipe(
        map(([filter, nodes]) => {
          if (!filter || filter.length === 0) {
            return nodes;
          }
          return nodes.filter(node => filter.some(letter => node.name[0] === letter));
        })
      );
    } else if (this.type === 'conditions') {
      this.Content$ = combineLatest([this.filter$, this.conditionService.Content$]).pipe(
        map(([filter, nodes]) => {
          if (!filter || filter.length === 0) {
            return nodes;
          }
          return nodes.filter(node => filter.some(letter => node.name[0] === letter));
        })
      );
    }
  }

  public filter(...param: string[]): void {
    this.filter$.next(param);
  }

}
