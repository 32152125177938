<div class="container dropdown-page">
  <h1>About ASCP SkinPro</h1>

  <p>You've got the knowledge, the skills, and the drive, but sometimes you need to consult with another <em>Skin Pro</em>. That's why Associated Skin Care Professionals (ASCP) has created this exciting web-based app that informs and validates your treatment decisions. </p>
  
  <p>Designed to aid in skin analysis, ASCP SkinPro puts the essential knowledge of contraindications for common skin-related conditions and ingredients at your fingertips and will help you customize treatment plans to improve your client outcomes and elevate your skin care practice.</p>
  
  <h2>It's Easy to Use</h2>
  
  <p>Picture this: You're in the treatment room and a client tells you they have atopic dermatitis and several allergies. You're pretty sure you know how to proceed with treatment, but you want to be sure. Simply open the ASCP SkinPro app, search for the condition, and you've got the expert information you need to help make treatment room decisions. You can also use the app to quickly and easily look up ingredients, identifying important contraindications and best practices so you can adjust your treatment plan on the spot.</p>
  
  <p>Confidently elevate your treatment plans by consulting with ASCP SkinPro. The ASCP team sourced information from a variety of clinical journals and worked with Mark Lees, PhD, MS, author of <em>Skin Care: Beyond the Basics</em> and a longtime contributor to ASCP <em>Skin Deep</em> magazine, to bring it all together. Plus, ASCP SkinPro uses progressive web app technology so you can easily save it to your mobile device without it taking up the space of an app from the app store. Remember, ASCP SkinPro is an exclusive benefit for ASCP members.</p>
  
  <h2>Why Isn't My Condition or Ingredient Listed?</h2>
  
  <p>ASCP SkinPro focuses on the most common conditions and ingredients estheticians might find while working in the treatment room. While this list was vetted to bring you information on conditions and ingredients you will see again and again throughout the course of your career, the list is always growing. Go to the ASCP SkinPro home page and click “List All Ingredients” or “List All Conditions” to see if your term shows up in the program.</p>
  
  <h2>Caution First</h2>
  
  <p>The information provided within ASCP SkinPro is not intended as medical advice. Care has been taken to confirm the accuracy of the information presented herein and to describe generally accepted practices. However, ASCP, the author, editors, and publishers involved in this project, are not responsible for omissions or errors or for any consequences from application of the information in this program, and make no warranty, expressed or implied, regarding the completeness or accuracy of the contents of this publication. Application of the information in a particular situation remains the practitioner's professional responsibility, as is their adherence to scope of practice.</p>
  
  <p>Questions about ASCP SkinPro? Contact us at <a href="mailto:getconnected@ascpskincare.com">getconnected@ascpskincare.com</a>.</p>
</div>