import { Component, ElementRef, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { faBars, faChevronLeft, faTimes, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkSolid, faHistory, faUndo, faHome } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

import { UserService } from '../../../services/user.service';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {

  public faHome = faHome;
  public faHistory = faHistory;
  public faUndo = faUndo;
  public faBookmarkRegular = faBookmarkRegular;
  public faBookmarkSolid = faBookmarkSolid;
  
  public faChevronLeft = faChevronLeft;
  public faTimes = faTimes;
  public faWindowClose = faWindowClose;
  public faBars = faBars;

  public showAppMenuDropdown$: Observable<boolean> = this.userService.User$.pipe(
    filter(user => user instanceof User),
    map(user => user.IsLoggedIn()),
    shareReplay(1)
  );

  public showSearchbar$: Observable<boolean> = combineLatest([this.showAppMenuDropdown$, this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))]).pipe(
    map(([isLoggedIn, routeEvent]) => {
      return isLoggedIn && !(routeEvent.url == '/' || routeEvent.url == '/search' || routeEvent.url == '/login' || routeEvent.url == '/access-denied');
    })
  );

  public notLoginRoute$: Observable<boolean> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((routeEvent) => {
      return (routeEvent.url != '/login');
    })
  );

  @Input()
  public hideSearch: boolean = false;

  @Input()
  public hideMenu: boolean = true;

  @ViewChild('nav', { static: true })
  public nav: ElementRef<HTMLElement>;

  constructor(
    @Inject(DEPLOY_URL) public deployUrl: string, 
    public router: Router, 
    public route: ActivatedRoute, 
    public userService: UserService,
    private location: Location) {
      
  }

  ngOnInit(): void {

  }

  public back(): void {
    this.location.back();
  }

}
