import { InjectionToken } from "@angular/core";

export interface IEnvironment {
  production: boolean;
  name: string;
  api: string;
  netforum: string;
  BuildTime: Date;
}

export const Environment = new InjectionToken<IEnvironment>('Environment');
