<div class="container dropdown-page container-lg mt-sm-0 pt-sm-0 me-lg-3 me-md-3 mt-sm-4">
  <div class="row justify-content-end">
    <div class="col-lg-8 col-md-12 col-sm-10 col-xs-10 mt-md-4 ps-3 pe-3">
      <main class="transparent-card pt-3 pb-3 ps-3 pe-3">
        <h1 class="mb-3">How To Install SkinPro</h1>
        <div class="field-item even">
          <p>ASCP SkinPro is a "progressive web app," which means you can save it to your phone, mobile device, or computer so that it works like an app from the app store, but it takes up much less space. The app can recognize the device that you're using, and the instructions below will match that device. So, for example, if you want to save ASCP SkinPro to your phone, log in with your phone and follow the instructions below.</p>
          <hr>
          <ng-container class="" *ngIf="isIOS === true || deviceOS === 'Mac' || macTablet === true">
            <div class="safari" *ngIf="devicebrowser === 'Safari'">
              <h5><strong>iOS/macOS Safari Users</strong></h5>
              <div class="clearfix">
                <ol>
                  <li>Tap the share action icon
                    <img src="{{ deployUrl }}assets/images/pwa/ios-share-shortcut-btn36px.png" class="icon_reference align-bottom"> at the bottom of the screen.
                  </li>
                  <li>
                    <p>Scroll down and tap on <br /><strong>Add to Home Screen</strong>.</p>
                    <p><img class="screen_reference img-fluid" src="{{ deployUrl }}assets/images/pwa/ios_add_to_home_screen_1.png"></p>
                  </li>
                  <li>
                    <p>Tap <strong>Add</strong>.</p>
                    <p><img class="screen_reference img-fluid" src="{{ deployUrl }}assets/images/pwa/ios_add_to_home_screen_2.png"></p>
                  </li>
                  <hr class="divider">
                  <li>
                    <p><img class="icon_reference_small float-start me-3" src="{{ deployUrl }}assets/icons/icon-144x144.png"></p>
                    <p>The icon will now be on your Home Screen, and you can open SkinPro by clicking on the icon.
                      <em>(You may be prompted to log in again, the first time you open it using the icon.)</em>
                    </p>
                  </li>
                </ol>
              </div>
              <hr class="divider">
              <p><strong>Still Not Working?</strong></p>
              <ol>
                <li>
                  <p>Check the footer for a Safari icon near the bottom right of your screen. This means you're viewing the page from inside another app, instead of the Safari browser.</p>
                  <p class="clearfix"><img class="icon_reference_small img-fluid float-none" src="{{ deployUrl }}assets/images/pwa/ios-footer-embedded-gmail-app.jpg"></p>
                </li>
                <li>
                  <p>Tap that icon to open SkinPro in Safari, then follow the instructions above.</p>
                </li>
                <li>
                  <p>Still can't install the app? Click on the "Browser Detail" link below and email it to <a href="mailto:getconnected@ascpskincare.com?subject=Adding App to Home Screen ({{deviceOS}} | {{devicebrowser}} | {{browserMainVersion}}) ">getconnected@ascpskincare.com</a>.</p>
                </li>
              </ol>
            </div>
            <div *ngIf="devicebrowser !== 'Safari'">
              <h5><strong>iOS Users</strong></h5>
              <p><strong>Please use Safari to enable the Home Screen App feature on iOS.</strong></p>
            </div>
          </ng-container>
          <ng-container *ngIf="isMobi === true || isTablet === true">
            <ng-container *ngIf="isAndroid === true">
              <div *ngIf="devicebrowser === 'Chrome'">
                <h5><strong>Android Chrome Users</strong></h5>
                <div *ngIf="browserMainVersion < 73">
                  <p><strong>Please update Chrome to enable the Home Screen App feature.</strong></p>
                </div>
                <div *ngIf="browserMainVersion > 72">
                  <div class="clearfix">
                    <ol>
                      <li>
                        <p>If you see this bar at the bottom of the home page, tap on it.</p>
                        <p><img alt="android chrome app install menu" class="img-fluid" alt="Add ASCP SkinPro to Home Screen" src="{{ deployUrl }}assets/images/pwa/android-chrome-add-to-home.png" /></p>
                        <p>If you don't see that bar at the bottom of the home page, then tap the menu icon to open the menu <img alt="" class="img-fluid" width="32" height="32" src="{{ deployUrl }}assets/images/pwa/android-menu-40px.png" /> and tap
                          <strong>Add to Home Screen</strong>.
                        </p>
                      </li>
                      <li>
                        <p>When prompted to install the app, tap <strong>Install</strong>.</p>
                        <p><img alt="" class="img-fluid" alt="Install app" src="{{ deployUrl }}assets/images/pwa/android-chrome-install-app.png" /></p>
                        <hr class="divider">
                      </li>
                      <li>
                        <p><img class="icon_reference_small float-start me-3 mb-2" src="{{ deployUrl }}assets/icons/icon-144x144.png"></p>
                        <p>The icon will now be on your Home Screen, and you can open SkinPro by clicking on the icon. <em>(You may be prompted to log in again, the first time you open it using the icon).</em></p>
                      </li>
                    </ol>
                  </div>
                  <hr class="divider">
                  <p><strong>Still Not Working?</strong></p>
                  <ol>
                    <li>
                      <p>Check to see if there's an "X" or left arrow at the top left of your screen. This means you're viewing the page from inside another app, instead of the Chrome browser.</p>
                      <p class="clearfix"><img class="icon_reference_small img-fluid float-none" src="{{ deployUrl }}assets/images/pwa/android-chrome-embedded.png"></p>
                    </li>
                    <li>
                      <p>Tap the 3 dots on the right to open the menu.</p>
                      <p class="clearfix"></p>
                    </li>
                    <li>Select <strong>Open in browser</strong>, then follow the instructions above to add SkinPro to your home screen.</li>
                    <li>
                      <p>Still can't install the app? Click on the "Browser Detail" link below and email it to <a href="mailto:getconnected@ascpskincare.com?subject=Adding App to Home Screen ({{deviceOS}} | {{devicebrowser}} | {{browserMainVersion}})">getconnected@ascpskincare.com</a>.</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div *ngIf="devicebrowser === 'Firefox' && (isAndroid === true || isIOS === true)">
                <h5><strong>Android Firefox Users</strong></h5>
                <div *ngIf="browserMainVersion < 58">
                  <p><strong>Please update Firefox to enable the Home Screen App feature.</strong></p>
                </div>
                <div *ngIf="browserMainVersion > 57">
                  <ol>
                    <li>
                      <p>To the right of the URL will be a More icon (three vertical dots).</p>
                      <p><img class="img-fluid" alt="firefox menu add to home screen icon step 1" src="{{ deployUrl }}assets/images/pwa/android-firefox-more.png" /></p>
                    </li>
                    <li>
                      <p>Select "Install" from the pop-up menu.</p>
                      <p><img class="img-fluid" alt="firefox menu add to home screen step 2" src="{{ deployUrl }}assets/images/pwa/android-firefox-install.png" /></p>
                    </li>
                    <li>
                      <p>On the next pop-up, select "Add Automatically".</p>
                      <p><img class="img-fluid" alt="firefox menu add to home screen step 2" src="{{ deployUrl }}assets/images/pwa/android-firefox-add-to-home.png" /></p>
                    </li>
                    <li>
                      <p><img class="icon_reference_small float-start me-3 img-fluid" src="{{ deployUrl }}assets/icons/icon-144x144.png"> Once added, the icon will provide a link to the website without ever opening the browser. This website will operate just like any other app that you were to install onto your device via the Google Play Store.</p>
                      <p class="clearfix"></p>
                    </li>
                  </ol>
                </div>
              </div>
              <div *ngIf="devicebrowser !== 'Chrome' && devicebrowser !== 'Firefox'">
                <p>{{ devicebrowser }}</p>
                <h5><strong>We recommend using Chrome</strong></h5>
                <p><strong>Please install Chrome or Firefox to enable Home Screen App feature.</strong></p>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="(isMobi === false) && (isTablet === false)">
            <div *ngIf="devicebrowser == 'Chrome'">
              <h5><strong>Chrome Desktop Users</strong></h5>
              <ol>
                <li>
                  <p class="clearfix">Click the Download indicator at the top right of your browser window.</p>
                  <p><img alt="" class="img-fluid" src="{{ deployUrl }}assets/images/pwa/chrome-screenshot-desktop-pwa-plus-indicator3.png" /></p>
                </li>
                <li>
                  <p>Then click <strong>Install</strong>.</p>
                  <p><img alt="" src="{{ deployUrl }}assets/images/pwa/chrome-app-install-alert-1.png" /></p>
                </li>
                <li>
                  <hr>
                  <p>If you don't see that option, click the menu icon at the top right of your browser window to open the Chrome menu. <img class="img-fluid" alt="chrome menu add to home screen icon" src="{{ deployUrl }}assets/images/pwa/chrome-menu-icon.png" />
                  </p>
                </li>
                <li>
                  <p>Select <strong>Install ASCP SkinPro</strong>.</p>
                  <p><img class="img-fluid" alt="chrome menu add to home screen icon step to" src="{{ deployUrl }}assets/images/pwa/chrome-add-to-home-menu-2.png" /></p>
                </li>
                <li>
                  <p>Then click <strong>Install</strong>.</p>
                  <p><img alt="" src="{{ deployUrl }}assets/images/pwa/chrome-app-install-alert-1.png" /></p>
                </li>
                <li>
                  <hr class="divider">
                  <p class="lastparagraph clearfix"><img class="icon_reference_small float-start me-3 mb-2" src="{{ deployUrl }}assets/icons/icon-144x144.png"> The icon will save to your Desktop, and you can open SkinPro by clicking on the icon. <em>(You may be prompted to log in again, the first time you open it using the icon).</em>
                  </p>
                </li>
                <li>
                  <hr class="divider mt-2">
                  <p>Still can't install the app? Click on the "Browser Detail" link below and email it to <a href="mailto:getconnected@ascpskincare.com?subject=Adding App to Home Screen ({{deviceOS}} | {{devicebrowser}} | {{browserMainVersion}}) ">getconnected@ascpskincare.com</a>.
                  </p>
                  <p class="divider clearfix">&nbsp;</p>
                </li>
              </ol>
            </div>
            <div *ngIf="devicebrowser === 'MS-Edge-Chromium'">
              <h5><strong>Edge Desktop Users</strong></h5>
              <ol>
                <li>
                  <p class="clearfix">Click the Plus indicator at the top right of your browser window.</p>
                  <p><img alt="" class="img-fluid " src="{{ deployUrl }}assets/images/pwa/edge-screenshot-desktop-pwa-plus-indicator-3.png" /></p>
                </li>
                <li>
                  <p>Then click <strong>Install</strong>.</p>
                  <p><img alt="" src="{{ deployUrl }}assets/images/pwa/edge-app-install-alert-1.png" /></p>
                </li>
                <li>
                  <hr>
                  <p>If you don't see that option, click the menu icon at the top right of your browser window to open the Edge menu. <img class="img-fluid" alt="" src="{{ deployUrl }}assets/images/pwa/edge-menu-icon.png" />
                  </p>
                </li>
                <li>
                  <p>Select <strong>Install ASCP SkinPro</strong>.</p>
                  <p><img class="img-fluid" alt="" src="{{ deployUrl }}assets/images/pwa/edge-add-to-home-menu-2a.png" /></p>
                </li>
                <li>
                  <p>Then click <strong>Install</strong>.</p>
                  <p><img alt="" src="{{ deployUrl }}assets/images/pwa/edge-app-install-alert-1.png" /></p>
                </li>
                <li>
                  <p>The app will install, and display another window with more options. You can select additional options here as desired. Select <strong>Allow</strong>.</p>
                  <p><img alt="" src="{{ deployUrl }}assets/images/pwa/edge-app-installed-windows-4.png" /></p>
                </li>
                <li>
                  <hr class="divider">
                  <p class="lastparagraph clearfix"><img class="icon_reference_small float-start me-3 mb-2" src="{{ deployUrl }}assets/icons/icon-144x144.png"> The icon will save to your Start Menu, and you can open SkinPro by clicking on the icon.
                    <em>(You may be prompted to log in again, the first time you open it using the icon).</em>
                  </p>
                  <p><img alt="" src="{{ deployUrl }}assets/images/pwa/edge-app-installed-windows-start-menu-5a.png" /></p>
                </li>
                <li>
                  <hr class="divider mt-2">
                  <p>Still can't install the app? Click on the "Browser Detail" link below and email it to <a href="mailto:getconnected@ascpskincare.com?subject=Adding App to Home Screen ({{deviceOS}} | {{devicebrowser}} | {{browserMainVersion}}) ">getconnected@ascpskincare.com</a>.
                  </p>
                  <p class="divider clearfix">&nbsp;</p>
                </li>
              </ol>
            </div>
            <div *ngIf="devicebrowser === 'Firefox'">
              <h5><strong>Firefox Desktop Users</strong></h5>
              <ol>
                <li>
                  <p>We've detected you are using a desktop version of Firefox, which does not support Add to Home Screen. However, you can add SkinPro to your bookmarks by clicking the star in the address bar at the top right of your screen.</p>
                  <p><img alt="" class="img-fluid " src="{{ deployUrl }}assets/images/pwa/desktop-firefox-bookmark-add.png" /></p>
                </li>
                <li>
                  <p>On the pop-up window, click Save.</p>
                  <p><img alt="" class="img-fluid " src="{{ deployUrl }}assets/images/pwa/desktop-firefox-bookmark-save.png" /></p>
                </li>
                <li>
                  <p>You will now see a new bookmark called <strong>ASCP SkinPro</strong> in the bookmarks toolbar.</p>
                  <p><img alt="" class="img-fluid " src="{{ deployUrl }}assets/images/pwa/desktop-firefox-bookmark-toolbar.png" /></p>
                </li>
              </ol>
            </div>
            <div *ngIf="devicebrowser !== 'Chrome' && devicebrowser !== 'Firefox' && devicebrowser !== 'Safari' && devicebrowser !== 'MS-Edge-Chromium'">
              <p>{{ devicebrowser }}</p>
              <h5><strong>We recommend using Chrome</strong></h5>
              <p><strong>Please install Chrome or Firefox to enable Home Screen App feature.</strong></p>
            </div>
          </ng-container>
          <p class="text-right smaller-text">
            <a href="https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/" target="_blank"><em>(More info on how to install web apps)</em></a>
          </p>
          <div id="debug" class="appVersion text-right versiontest smaller-text">
            <p>Built for {{environment.name}} on {{environment.BuildTime}}</p>
            <p><a class="text-primary versiontest" href="https://www.whatsmybrowser.org/" target="_blank">Browser detail</a></p>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
