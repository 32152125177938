<h1 class="content-list mt-4">{{ type }}</h1>

<div class="row justify-content-center mt-1 mb-2">
  <div class="d-flex">
    <nav class="btn-toolbar" role="toolbar" aria-label="Jump List">
      <div class="btn-group me-2" role="group" aria-label="Alphabet">
        <span type="button" class="alpha-sort me-3" (click)="filter('A', 'B', 'C')">A-C</span>
        <span type="button" class="alpha-sort me-3" (click)="filter('D', 'E', 'F', 'G', 'H')">D-H</span>
        <span type="button" class="alpha-sort me-3"
          (click)="filter('I', 'J', 'K', 'L', 'M', 'N', 'O', 'P')">I-P</span>
        <span type="button" class="alpha-sort me-3"
          (click)="filter('Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z')">Q-Z</span>
      </div>
      <div class="btn-group me-2" role="group" aria-label="Navigation Link">
        <span type="button" class="alpha-sort" (click)="filter()">All</span>
      </div>
    </nav>
  </div>
</div>

<content-short class="mb-3" *ngFor="let contentnode of Content$ | async" [ContentNode]="contentnode">
</content-short>
