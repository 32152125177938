import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  public activeTab: string = 'overview';
  public isCollapsed: boolean = true;
  private subs = new SubSink();

  @Input()
  public Summary: string = '';

  @Input()
  public Overview: string = '';

  @Input()
  public Considerations: string = '';

  @Input()
  public Contraindications: string = '';

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subs = new SubSink();

    this.subs.sink = this.route.paramMap.subscribe(param => {
      subs.unsubscribe();
      this.activeTab = 'overview';
    });
  }

  openTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activeTab = activeTab;
  }

}
