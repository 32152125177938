import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { IContentAlias } from '../models/content.interface';
import { Environment, IEnvironment } from '../models/environment';
import { Ingredient } from '../models/ingredient.model';
import { ContentService } from './content.service';

@Injectable({
  providedIn: 'root'
})
export class IngredientService extends ContentService<Ingredient> implements OnDestroy {

  public constructor(
    @Inject(Environment) environment: IEnvironment,
    http: HttpClient,
    modalService: BsModalService) {
    super('ingredients', environment, http, modalService);
  }

  public ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  protected Instantiate(source: IContentAlias): Ingredient {
    return new Ingredient(source);
  }

}
