import { Component, Inject, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AllContent, TitleMatch } from 'src/app/services/helpers';
import { ConditionService } from 'src/app/services/condition.service';
import { IngredientService } from 'src/app/services/ingredient.service';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

@Component({
  templateUrl: './content-search-box.component.html',
  styleUrls: ['./content-search-box.component.scss']
})
export class ContentSearchBoxComponent implements OnInit {

  public ContentNodes$: Observable<AllContent[]>;
  public search$ = new BehaviorSubject<[string, boolean]>(null);

  constructor(@Inject(DEPLOY_URL) public deployUrl: string, private conditionService: ConditionService, private ingredientService: IngredientService) {
    
  }

  ngOnInit(): void {

    this.ContentNodes$ = combineLatest([this.search$, this.conditionService.Content$, this.ingredientService.Content$]).pipe(
      map(([search, conditions, ingredients]) => {

        if (!search) {
          return [];
        }

        const nodes = [...conditions, ...ingredients];
        const [term, deep] = search;
        let keywords: string[];

        if (term === null || term.length < 2) {
          return [];
        }

        if (deep) {
          keywords = term.split(/[\,\s]+/).map(word => word.replace(/[^\w\d]/, ''));
        }

        return nodes.filter(node => {
          let match = TitleMatch(node, term);

          if (deep) {
            let keywordMatch = false;
            for (const word of keywords) {
              keywordMatch = node.common_names.some(item => item.toString().indexOf(word) !== -1);
              if (keywordMatch) {
                break;
              }
            }

            match = match || keywordMatch;
          }

          return match;
        });
      })
    );
  }

}
