<fieldset>
  <div class="form-row justify-content-center mb-3">
    <div class="col-12">
      <label class="sr-only" for="search-term" id="search-term-label">Search for ingredient or condition</label>

      <div class="input-group">
        <div class="input-group-prepend">
          <em class="input-group-text search-box-left">
            <fa-icon class="search-bar-icon" [icon]="faSearch">Search</fa-icon>
          </em>
        </div>
        
        <input class="form-control" #searchTerm
          (input)="Search(searchTerm.value, false)" id="search-term" type="search" autocomplete="off"
          aria-describedby="search-term-label" placeholder="Try &quot;rosacea&quot;" autofocus required>
        <div *ngIf="(searchTerm.value.trim() !== '')" class="input-group-append">
          <button class="btn btn-outline-secondary" type="reset"
            (click)="Search(searchTerm.value = '', false);"><fa-icon [icon]="faTimes">Clear</fa-icon></button>
        </div>
      </div>

    </div>
  </div>
</fieldset>
  