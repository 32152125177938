import { Component, Inject, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Environment, IEnvironment } from '../../models/environment';
import { DEPLOY_URL } from '../../tokens/deploy-url';

@Component({
  templateUrl: './add-to-home-screen.component.html',
  styleUrls: ['./add-to-home-screen.component.scss']
})
export class AddToHomeScreenComponent implements OnInit {

  private deviceInfo = null;

  public browserVersion: string;
  public browserMainVersion: number;
  public devicebrowser: string;
  public deviceOS: string;
  public iOSandMobi: boolean;
  public iosMobiSafari: boolean;
  public isAndroid: boolean;
  public isIOS: boolean;
  public isMobi: boolean;
  public isTablet: boolean;
  public macTablet: boolean;
  public osCookieValue = 'UNKNOWN';

  constructor(@Inject(Environment) public environment: IEnvironment, @Inject(DEPLOY_URL) public deployUrl: string, private dd: DeviceDetectorService) {
    this.DeviceTest();
  }

  ngOnInit(): void {
  }

  public DeviceTest(): void {
    this.deviceInfo = this.dd.getDeviceInfo();
    this.isMobi = this.dd.isMobile();
    this.isTablet = this.dd.isTablet();
    this.deviceOS = this.deviceInfo.os;
    this.devicebrowser = this.deviceInfo.browser;

    this.browserVersion = this.deviceInfo.browser_version;
    this.browserMainVersion = parseInt(this.browserVersion.split('.')[0], 10) || 0;

    if ((this.deviceOS === 'Mac') && (this.isTablet === true)) {
      this.macTablet = true;
    }
    if (this.deviceOS === 'iOS') {
      this.isIOS = true;
      this.isAndroid = false;
    } else if (this.deviceOS === 'Android') {
      this.isAndroid = true;
      this.isIOS = false;
    } else {
      this.isAndroid = false;
      this.isIOS = false;
    }
  }

}
