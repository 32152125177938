<form class="card login-form mt-4">
  <h1 class="card-title text-center cleanBorder pt-4">ASCP Account Login</h1>

  <fieldset class="card-body" [disabled]="Busy$ | async" [formGroup]="form">

    <alert type="danger" *ngIf="Error$ | async as Error">
      <p [innerText]="Error.message"></p>
    </alert>

    <div class="form-group text-start px-3">
      <input class="form-control" [ngClass]="{ 'is-invalid': username.invalid && username.touched }" type="email" required formControlName="username" />
      <label for="username" *ngIf="username.untouched || username.valid; else usernameError" class="form-text text-muted">Email address</label>
      <ng-template #usernameError>
        <p class="invalid-feedback">
          Email is required and must be a valid email address.
        </p>
      </ng-template>
    </div>

    <div class="form-group text-start px-3">
      <input class="form-control" [ngClass]="{ 'is-invalid': password.invalid && password.touched }" type="password" required formControlName="password" />
      <label for="password" *ngIf="password.untouched || password.valid; else passwordError" class="form-text text-muted">Password</label>
      <ng-template #passwordError>
        <p class="invalid-feedback">
          Password is required
        </p>
      </ng-template>
    </div>

    <div class="row justify-content-md-center px-3">
      <button [disabled]="form.invalid" type="submit" class="btn btn-lg btn-primary col-md-6 login-btn" (click)="Submit()">Log In</button>
    </div>

  </fieldset>
</form>
