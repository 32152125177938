import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Environment, IEnvironment } from './app/models/environment';
import { DEPLOY_URL } from './app/tokens/deploy-url';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const deployUrl = (function() {
  const script = document.querySelector('script[src*="main."]') as HTMLScriptElement;
  return script.getAttribute('src').replace(/main.*?\.js$/, '');
})();

let ENVIRONMENT: IEnvironment = environment;

fetch('environment.json')
  .then(response => response.json())
  .then(environment => {
    ENVIRONMENT = {...environment};
    return platformBrowserDynamic([
      {
        provide: Environment,
        useValue: ENVIRONMENT
      },
      {
        provide: DEPLOY_URL,
        useValue: deployUrl
      }
    ]).bootstrapModule(AppModule)
  })
  .catch(err => console.error(err));
