import { Component, OnInit, Inject } from '@angular/core';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

@Component({
  templateUrl: './about-mark-lees.component.html',
  styleUrls: ['./about-mark-lees.component.scss']
})
export class AboutMarkLeesComponent implements OnInit {

  constructor(@Inject(DEPLOY_URL) public deployUrl: string) { }

  ngOnInit(): void {
  }

}
