import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { tokenGetter } from '../../../helpers/tokenGetter';
import { UserService } from '../../../../app/services/user.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  private sink: SubSink = new SubSink();

  public form: FormGroup = new FormGroup({});
  public username: FormControl = new FormControl();
  public password: FormControl = new FormControl();

  public Busy$: Subject<boolean> = new Subject();
  public Error$: Subject<Error> = new ReplaySubject(1);

  constructor(private userService: UserService, private jwtHelper: JwtHelperService) {
  }

  ngOnInit(): void {
    this.Busy$.next(false);

    this.username = new FormControl(null, [ Validators.required, Validators.email ]);
    this.password = new FormControl(null, [ Validators.required ]);

    this.form = new FormGroup({
      username: this.username,
      password: this.password,
    });

    const token = tokenGetter();
    if (token) {
      const isExpired = this.jwtHelper.isTokenExpired(token);
      if (isExpired) {
         this.Error$.next(new Error('Your login session has expired. Please log in again.'));
      }
    }
  }

  public ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  public Submit(): void {
    if (this.form.valid) {
      this.Busy$.next(true);
      this.Error$.next(null);

      this.sink.sink = this.userService.Login(this.username.value, this.password.value).pipe(take(1)).subscribe({
        next: user => {
          // Redirect handled by parent component
          this.sink.unsubscribe();
       },
        error: error => {
          this.sink.unsubscribe();
          this.Error$.next(error);
          this.Busy$.next(false);
        }
      });
    }
  }

}
