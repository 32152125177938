import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, timer } from 'rxjs';
import { filter, mapTo, shareReplay, take } from 'rxjs/operators';

import { User } from '../../../app/models/user.model';
import { UserService } from '../../../app/services/user.service';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public Timer$: Observable<any> = timer(1500).pipe(shareReplay(1), mapTo(true));

  constructor(
    @Inject(DEPLOY_URL) public deployUrl: string,
    private router: Router,
    private userService: UserService) {
  }

  public ngOnInit(): void {
    this.userService.User$.pipe(filter(user => user instanceof User && user.IsLoggedIn()), take(1)).subscribe(user => {
      this.router.navigate(['/search']);
    });
  }

}
