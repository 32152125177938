import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { tokenSetter } from "../helpers/tokenSetter";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(tap(response => {
			if (response instanceof HttpResponse) {
				if (response.headers.has('X-JWT')) {
					tokenSetter(response.headers.get('X-JWT') as string);
				}
			}
		}));
	}

}
