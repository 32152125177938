import { ContentTypes } from '../helpers/content-types';
import { GetAlias } from '../services/helpers';
import { IContentAlias, IRelatedContent } from './content.interface';

export abstract class Content implements IContentAlias {
  public nid: number = 0;
  public name: string = '';
  public definition: string = '';
  public overview: string = '';
  public summary: string = '';
  public considerations: string = '';
  public contraindications: string = '';
  public research_and_resources: string = '';
  public related_conditions: IRelatedContent[] = [];
  public related_ingredients: IRelatedContent[] = [];
  public common_names: string[] = [];
  public image: Object = {};
  public alias: string = '';
  public readonly abstract Type: ContentTypes;

  constructor(source?: any) {
    if (source) {
      for (const key in this) {
        if (key in source) {
          switch (key) {
            case 'related_conditions':
            case 'related_ingredients':
              (this[key] as any) = (source[key] as IRelatedContent[]).map(related => ({
                ...related,
                alias: GetAlias(related.name)
              }));
              break;
            default:
              this[key] = source[key];
          }
        }
      }
    }
  }

  public GetContentStub() {
    return {
      nid: this.nid,
      name: this.name,
      overview: this.overview
    };
  }
}
