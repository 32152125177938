import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';

import { pwaToastGetter } from '../../../helpers/pwaToastGetter';
import { pwaToastSetter } from '../../../helpers/pwaToastSetter';
import { ConditionService } from '../../../services/condition.service';
import { IngredientService } from '../../../services/ingredient.service';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

@Component({
  templateUrl: './content-search.component.html',
  styleUrls: ['./content-search.component.scss']
})
export class ContentSearchComponent implements OnInit {

  private deviceInfo = null;
  private deviceOS: string;

  public Loading$: Observable<any>;

  constructor(
    @Inject(DEPLOY_URL) public deployUrl: string,
    private router: Router,
    private toastr: ToastrService,
    private conditionService: ConditionService,
    private ingredientService: IngredientService,
    private dd: DeviceDetectorService) {
      this.deviceInfo = this.dd.getDeviceInfo();
      this.deviceOS = this.deviceInfo.os;
  }

  ngOnInit(): void {
    this.Loading$ = this.ingredientService.Content$.pipe(switchMap(() => this.conditionService.Content$))

    if (this.deviceOS != 'Android' && pwaToastGetter() != 'yes') {
      this.toastr.info('Please click here for instructions to install ASCP SkinPro', '', {
        disableTimeOut: true,
        positionClass: 'toast-bottom-center',
        closeButton: true
      }).onTap.pipe(take(1)).subscribe(() => {
        pwaToastSetter('yes');
        this.router.navigate(['/add-to-home-screen']);
      });
    }
  }

}
