<div class="container-lg">
  <main class="row justify-content-md-center">
    <div *ngIf="Timer$ | async; else loading" class="col col-md-10 col-lg-8 pt-5 login-page">
      <app-login-form></app-login-form>

      <div class="jumbotron text-center">
        <p class="lead">Not an ASCP Member?</p>
        <p><a class="join-now" href="/signup" target="_blank">Join Now</a></p>
      </div>
    </div>
  </main>
</div>

<ng-template #loading>
  <div class="jumbotron text-center mt-3">
    <p>Loading...</p>
  </div>
</ng-template>
