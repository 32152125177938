import { Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map, share, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { AuthGuardService } from './services/auth-guard.service';
import { UserService } from './services/user.service';
import { slideInAnimation } from './route-animation';
import { tokenSetter } from './helpers/tokenSetter';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})
export class AppComponent implements OnInit, OnDestroy {

  private sink: SubSink = new SubSink();

  public hideSearch: boolean = false;
  public hideMenu: boolean = true;
  public guardLoader$: Observable<boolean> = new Observable;
  public version: string = '1.0.0';

  private State$ = this.router.events.pipe(
    filter(event => event instanceof ResolveEnd),
    map((event: ResolveEnd) => event.url),
    map(url => url.startsWith('/ingredient') || url.startsWith('/condition') || url.startsWith('/login')),
    share(),
  );

  public HeaderClass$ = this.State$.pipe(map(state => state ? 'nav-background-img' : 'nav-background-md'));

  @HostBinding('class')
  public htmlClass: string = 'web-background-md';

  private token: string;

  constructor(
    element: ElementRef,
    private router: Router,
    private user: UserService,
    private guard: AuthGuardService,
    private toastr: ToastrService,
    private updates: SwUpdate) {
    this.token = (element.nativeElement as HTMLElement).getAttribute('token'); //<-- Uncomment this line and erase below for production; this is to bypass the login screen
    //this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4YTBkMDMzNS1iMWI2LTQ1NDAtYjczZC03NWMzYTk2YWM5NjEiLCJuYW1lIjoiand0OjEwMjQ5NTEiLCJnaXZlbl9uYW1lIjoiRHJldyIsImZhbWlseV9uYW1lIjoiWmFybiIsIm5pY2tuYW1lIjoiRHJldyIsImVtYWlsIjoienpkcmV3QGFibXAuY29tenoiLCJwYWMiOnsiY3N0X3JlY25vIjoxMDI0OTUxLCJleGFtY29hY2giOnsiYWN0aXZlIjp0cnVlLCJleHBpcmVzIjoiMjAyNC0wNC0wMVQwMDowMDowMCIsImNvaG9ydHMiOnt9fX0sImp0aSI6IjAzYjM3ZGQxLWJjY2ItNDZkNy1iZjI2LWJmMThhYTUyMDIzYiIsInJvbGVzIjpbIlBBQyBTVEFGRiIsIkFmZmluaXR5IFRpZXIgMiIsIlByZW1pZXIgU2Nob29sICsgSW5zdXJhbmNlIl0sIm5iZiI6MTcwNTM1MjQyOCwiZXhwIjoxNzA2NTYyMDI4LCJpYXQiOjE3MDUzNTI0Mjh9.G0zhzbiGTP9Phsx4hDdN6WydIxOhrcSi3pEvy2wonbo";

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        /** START : Code to Track Page View  */
        if (gtag && typeof gtag === 'function') {
          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
          });
        }
        /** END */
      })
  }

  public ngOnInit(): void {
    if (this.token) {
      tokenSetter(this.token);
    }

    this.user.authenticateFromToken();

    this.guardLoader$ = this.guard.Loader$;
    this.sink.sink = this.State$.subscribe(state => this.htmlClass = state ? 'bg-white' : 'web-background-md');
    this.sink.sink = this.user.User$.pipe(
      filter(user => user !== null && user.IsLoggedIn()),
      take(1)
    ).subscribe(() => {
      this.hideMenu = false;
    });

    this.sink.sink = this.updates.versionUpdates.subscribe((e) => {
      if (e.type === 'VERSION_READY') {
        this.toastr.info('A new app update is available. Click here to install.', 'Update', {
          disableTimeOut: true,
          positionClass: 'toast-bottom-center',
          closeButton: true
        }).onTap.pipe(take(1)).subscribe(() => location.reload());
      }
    });
  }

  public ngOnDestroy() {
    this.sink.unsubscribe();
  }
}
