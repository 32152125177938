import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AllContent } from 'src/app/services/helpers';

@Component({
  selector: 'content-short',
  templateUrl: './content-short.component.html',
  styleUrls: ['./content-short.component.scss']
})
export class ContentShortComponent implements OnInit {

  public content_type: string = '';

  @Input()
  public ContentNode: AllContent;

  constructor(public router: Router) { }

  ngOnInit(): void {
    if ((this.ContentNode.Type === 'ingredient')) {
      this.content_type = 'ingredients';
    } else if ((this.ContentNode.Type === 'condition')) {
      this.content_type = 'conditions';
    }
  }

  public encode(name: string): string {
    return encodeURI(name);
  }

}
