import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Condition } from "../models/condition.model";
import { IContentAlias } from "../models/content.interface";
import { Ingredient } from "../models/ingredient.model";

export type AllContent = Condition | Ingredient;

export function GetSingle<T extends IContentAlias>(data$: Observable<T[]>, name: string): Observable<T> {
  return data$.pipe(map(data => data.find(content =>
    content.alias == name
  )));
}

export function TitleMatch(content: AllContent, term: string): boolean {
  let match: boolean;
  match = match || content.name.toLowerCase().indexOf(term) !== -1;

  if (content.common_names) {
    match = match || (content.common_names.some(item => item.indexOf(term) !== -1));
  }

  return match;
}

export function GetAlias(name: string): string {
  return name.toLowerCase().trim().replace(/[^\sa-z]/g, '').replace(/[\s]/g, '-');
}
