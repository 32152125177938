import { Component } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent {

  modalRef?: BsModalRef;

  constructor(private modalService: BsModalService) { }

  public hide() {
    this.modalService.hide();
  }

}
