import { Component, Inject, Input, OnInit } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AllContent } from 'src/app/services/helpers';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  private content$: Subject<AllContent> = new ReplaySubject(1);

  public Image$ = this.content$.pipe(map(content => content.image ));
  public Images: Object = {};
  public imageMap: any[] = [];
  public imageSize: any[] = [];

  @Input()
  public set Content(content: AllContent) {
    this.content$.next(content);
  }

  constructor(@Inject(DEPLOY_URL) public deployUrl: string) {
  }

  ngOnInit(): void {
    this.Image$.subscribe((image) => {
      this.Images = image;
      this.imageMap = [
        `${this.Images['thumbnail'] ? this.Images['thumbnail'] : ''} 100w`,
        ` ${this.Images['phone'] ? this.Images['phone'] : ''} 400w`,
        ` ${this.Images['desktop'] ? this.Images['desktop'] : ''} 460w`,
        ` ${this.Images['tablet'] ? this.Images['tablet'] : ''} 640w`
      ];
    });

    this.imageSize = [
      "(max-width: 359px) 280px",
      "(min-width: 360px) and (max-width: 479px) 300px",
      "(min-width: 480px) and (max-width: 575px) 400px",
      "(min-width: 576px) and (max-width: 767px) 460px",
      "(min-width: 768px) and (max-width: 991px) 640px",
      "(min-width: 992px) and (max-width: 1199px) 360px",
      "(min-width: 1200px) and (max-width: 1399px) 400px",
      "(min-width: 1400px) 460px",
    ]
  }

}
