import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { ModalErrorComponent } from '../components/modal-error/modal-error.component';
import { IContent, IContentAlias } from '../models/content.interface';
import { Environment, IEnvironment } from '../models/environment';
import { GetAlias } from './helpers';

@Injectable({
  providedIn: 'root'
})
export abstract class ContentService<TYPE> {

  private modalRef?: BsModalRef;
  protected content$: Subject<TYPE[]> = new ReplaySubject(1);
  protected sink: SubSink = new SubSink();

  constructor(
    @Inject(String) protected readonly CONTENT_TYPE: 'ingredients' | 'conditions',
    @Inject(Environment) protected environment: IEnvironment,
    protected http: HttpClient,
    private modalService: BsModalService) {
    this.getJSON().subscribe(data => {
      this.content$.next(data.map(content => this.Instantiate(content)));
    });
  }

  private getJSON(): Observable<IContentAlias[]> {
    return this.http.get<IContent[]>(this.environment.api + this.CONTENT_TYPE).pipe(
      map(results => results.map(ParseContent)),
      shareReplay(1),
      catchError(e => this.handleApiError(e) )
    );
  }

  private handleApiError(err: any) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `Error: ${err.message}`;
    } else {
      errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }

    this.modalRef = this.modalService.show(ModalErrorComponent);
    return throwError(errorMessage);
  }

  protected abstract Instantiate(source: IContentAlias): TYPE;

  public get Content$(): Observable<TYPE[]> {
    return this.content$.asObservable();
  }
}

function ParseContent(content: IContent): IContentAlias {
  const parse = {
    ...content,
    alias: GetAlias(content.name)
  };

  return parse;
}
