<div #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Error</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Could not retrieve data, please reload page
  </div>
</div>