import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  public showSplash: boolean = true;

  constructor(@Inject(DEPLOY_URL) public deployUrl: string, private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.showSplash = !this.showSplash;
      this.router.navigate(['login']);
   }, 2000);
  }

}
