
<div class="container dropdown-page">
  <h1 class="faq-title">Using SkinPro</h1>

  <h2>How do estheticians use ASCP SkinPro?</h2>
  
  <p><strong>During consultation</strong>&mdash;Your client mentions a condition or ingredient allergy during the intake process. Need to be sure about contraindications?<br />
  <strong>During skin analysis</strong>&mdash;You observe a skin condition and want to make a quick decision about the best treatment.<br />
  <strong>While retailing</strong>&mdash;You're about to recommend a product for your client, but you want to confirm the contraindications and ensure the product will perform as needed.</p>
  
  <p>Simply open the app, search for the condition or ingredient, and identify important contraindications and best practices. With ASCP SkinPro, you've got the expert information you need to adjust your treatment plan on the spot.</p>
  
  <h2>I'm looking for a condition or ingredient that isn't listed in SkinPro. Why isn't it there?</h2>
  
  <p>ASCP SkinPro focuses on the most common conditions and ingredients estheticians might find while working in the treatment room. While this list was vetted to bring you information on conditions and ingredients you will see again and again throughout the course of your career, the list is always growing. Don't see what you are looking for? Go to the ASCP SkinPro home page and click “List All Ingredients” or “List All Conditions” to see if your term shows up in the program.</p>
  
  <h2>Can I use the information in ASCP SkinPro to diagnose or treat a client who may have a condition that is considered outside my scope of practice as an esthetician?</h2>
  
  <p>No. The information provided within ASCP SkinPro is not intended as medical advice. Care has been taken to confirm the accuracy of the information presented herein and to describe generally accepted practices. However, ASCP, the author, editors, and publishers involved in this project, are not responsible for omissions or errors or for any consequences from application of the information in this program, and make no warranty, expressed or implied, regarding the completeness or accuracy of the contents of this publication. Application of the information in a particular situation remains the practitioner's professional responsibility, as is their adherence to scope of practice.</p>
  
  <h2>What are the system requirements for ASCP SkinPro?</h2>
  
  <p>ASCP SkinPro is supported by mainstream Web browsers including Chrome, Safari, Firefox, and Microsoft Edge. If you are having trouble viewing ASCP SkinPro in one of these browsers, make sure you have a recent version of your browser installed.</p>
  
  <h2>How do I add SkinPro to my home screen?</h2>
  
  <p>Find the menu icon (top-right for desktop users, bottom navigation for mobile users). Click there and scroll down to "Add App to Home Screen." This will give you the directions you need to add ASCP SkinPro to your specific computer or device.</p>
</div>