import { IContentAlias } from './content.interface';
import { Content } from './content.model';

export class Condition extends Content {

  public readonly Type = 'condition';

  constructor(source?: IContentAlias) {
    super(source);
  }
}
