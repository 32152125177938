<nav #nav class="navbar navbar-light" [ngClass]="{ searchOpen: false, searchClose: true }" [ngClass]="{'navbar-login-route' : (notLoginRoute$ | async) === false}">
  <div class="col mobi_bck d-lg-none d-md-none ps-0 pe-0" ngClass="col-2 col-sm-2">
    <button *ngIf="notLoginRoute$ | async" class="btn back outline-none left" type="button" (click)="back()">
      <img src="{{ deployUrl }}assets/images/carot.png">
    </button>
  </div>

  <div class="col brand_wrap" ngClass="col-2 col-md-2 col-lg-2" [ngClass]="{'navbar-login-route-logo' : (notLoginRoute$ | async) === false}">
    <a class="navbar-brand" routerLink="search">
      <img class="image header-nav-logo" src="{{ deployUrl }}assets/images/skinpro_logo_new.svg">
    </a>
  </div>

  <app-nav-search-bar *ngIf="showSearchbar$ | async" class="col brand_wrap" ngClass="col-2 col-md-4"></app-nav-search-bar>

  <div *ngIf="showAppMenuDropdown$ | async" class="col-md-4 col-lg-4 d-none d-md-block pe-0">
    <app-menu-dropdown class="nav-item float-end"></app-menu-dropdown>
  </div>

</nav>