import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { GetSingle } from 'src/app/services/helpers';
import { Ingredient } from '../../models/ingredient.model';
import { IngredientService } from '../../services/ingredient.service';
import { RecentContentService } from 'src/app/services/recent-content.service';

@Component({
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.scss']
})
export class IngredientsComponent implements OnInit {

  public Ingredient$: Observable<Ingredient>;

  constructor(
    private route: ActivatedRoute,
    private ingredientService: IngredientService,
    private recentService: RecentContentService) {
  }

  ngOnInit(): void {
    this.Ingredient$ = this.route.paramMap.pipe(
      switchMap(param =>
        GetSingle(this.ingredientService.Content$, param.get('name'))
      ),
      tap(ingredient => this.recentService.Add({
        nid: ingredient.nid,
        status: true,
        type: ingredient.Type,
        name: ingredient.name,
        alias: ingredient.alias,
      })),
    );
  }

}
