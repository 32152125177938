import { animate, group, query, style, transition, trigger } from '@angular/animations';

const screen = query(':enter, :leave',
  style({
    position: 'fixed',
    width: '100%',
    height: 'calc(999px)'
  }),
  { optional: true });

const enterFromRight = query(':enter', [
  style({ transform: 'translateX(100%)' }),
  animate('0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    style({ transform: 'translateX(0%)' }))
], { optional: true });

const leaveToRight = query(':leave', [
  style({ transform: 'translateX(0%)' }),
  animate('0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    style({ transform: 'translateX(100%)' }))
], { optional: true });

const leaveToLeft = query(':leave', [
  style({ transform: 'translateX(0%)' }),
  animate('0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    style({ transform: 'translateX(-100%)' }))
], { optional: true });

const enterFromLeft = query(':enter', [
  style({ transform: 'translateX(-100%)' }),
  animate('0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    style({ transform: 'translateX(0%)' }))
], { optional: true });

const enterFromLeftLeaveToRight = [
  screen,
  group([
    enterFromLeft,
    leaveToRight,
  ])
];
export const slideInAnimation =
  trigger('routeAnimations', [
    transition('contentnode => *', [
      screen,
      group([
        enterFromRight,
        leaveToLeft,
      ])
    ]),
    transition('SlideFromLeft => *', enterFromLeftLeaveToRight),
    transition('* => *', enterFromLeftLeaveToRight),
  ]);
