import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AllContent } from 'src/app/services/helpers';
import { DEPLOY_URL } from 'src/app/tokens/deploy-url';
import { SubSink } from 'subsink';

import { FavoriteContentService } from '../../services/favorite-content.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit, OnDestroy {

  private content$: Subject<AllContent> = new ReplaySubject(1);
  private subsink: SubSink = new SubSink();

  public faBookmarkSolid = faBookmarkSolid;
  public farBookmark = farBookmark;

  public Favorite$: Subject<boolean> = new Subject<boolean>();
  public isFavorite$: Observable<boolean> = merge(this.content$.pipe(switchMap(content => this.favoriteService.Has$({ nid: content.nid, status: true, type: content.Type }))), this.Favorite$);
  public IconUrl$ = this.content$.pipe(map(content => this.deployUrl + `assets/images/icon_${content.Type}.png`));
  public Title$ = this.content$.pipe(map(content => content.name));

  @Input()
  public set Content(content: AllContent) {
    this.content$.next(content);
  }

  constructor(
    @Inject(DEPLOY_URL) public deployUrl: string,
    private favoriteService: FavoriteContentService) { }

  public ngOnInit(): void {
    this.subsink.sink = combineLatest([this.content$, this.Favorite$.pipe(debounceTime(500))]).subscribe(([content, status]) => {
      if (status) {
        this.favoriteService.Add({
          nid: content.nid,
          status: true,
          type: content.Type,
          alias: content.alias,
          name: content.name
        });
      } else {
        this.favoriteService.Remove({
          nid: content.nid,
          status: false,
          type: content.Type,
          alias: content.alias,
          name: content.name
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public ToggleFaveStar(status: boolean): void {
    if (this.Favorite$) {
      this.Favorite$.next(status);
    }
  }

}
