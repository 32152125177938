<div class="container-lg">
  <div class="row justify-content-end my-3">
    <div class="col col-lg-6 col-md-6">
      <main *ngIf="Type$ | async as Type">
        <h1 class="mt-3"><span class="border-start ms-1 me-2"></span> {{ Title$ | async }}</h1>

        <div *ngIf="(Content$ | async).length == 0" class="rowTest clearfix">
          <p>no {{ Type }} conditions or ingredients</p>
        </div>

        <div *ngIf="(Content$ | async).length > 0" class="rowTest clearfix">
          <button type="button" class="btn btn-secondary float-right mt-2 mb-2 remove" (click)="Clear(Type)">
            <span><fa-icon class="me-1" [icon]="faTimes">Delete</fa-icon> Clear All</span>
          </button>
        </div>

        <ul class="list-group saved-list">
          <li class="list-group-item transparent-card d-flex flex-row px-0 py-0" *ngFor="let item of Content$ | async; trackBy: trackBy">
            <a class="title flex-fill px-3 py-3 " [routerLink]="['/', item.type + 's', item.alias]" >{{ item.name }}</a>
            <button [disabled]="!item.status" class="btn btn-sm btn-light float-right px-3 remove" (click)="Remove(item, Type)">
              <span ><fa-icon [icon]="faTimes">Delete</fa-icon></span>
            </button>
          </li>
        </ul>
      </main>
    </div>
  </div>
</div>
