import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { ApplicationRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { ConnectionService } from './connection.service';
import { UserService } from './user.service';

export interface ISavedContent {
  nid: number;
  status: boolean;
  type: 'condition' | 'ingredient';
  timestamp?: number;
  name?: string;
  alias?: string;
}

@Injectable({
  providedIn: 'root'
})
export abstract class SavedContentService <T extends ISavedContent> implements OnDestroy {

  protected abstract readonly bufferTime: number;

  protected token: string;

  /**
   * Stream of user-triggered state changes
   */
  protected user$: Subject<T[]>;

  /**
   * Stream for server-triggered state changes
   */
  protected server$: Subject<T[]>;

  /**
   * Combined stream of user and server trigger changes,
   * used by the ui to show immediate reactions to user interaction
   * while the changes are buffered before sent to the server
   */
  protected state$: Observable<T[]>;

  /**
   * Stream of individual ui changes for queuing
   */
  protected changes$: Subject<T>;

  /**
   * Sink for unsubscribing to subscripions
   */
  protected subs = new SubSink();

  constructor(
    @Inject(null)
    protected readonly storage: string,
    protected appRef: ApplicationRef,
    protected userService: UserService,
    protected connection: ConnectionService) {
      this.storage = storage;
      
      this.userService.User$.subscribe(u => this.token = u.jwt);

      // Initialize standard streams
      this.user$ = new Subject();
      this.changes$ = new Subject();

      // Get cached copy of data
      const initial = this.getStorage();

      // Server stream has a default state based from localStorage cache
      this.server$ = initial ? new BehaviorSubject(initial) : new ReplaySubject(1);

      // Merge the user and server triggered state change stream
      this.state$ = merge(this.user$, this.server$);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getStorage(): T[] | false {
    if (localStorage && localStorage instanceof Storage) {
      const savedString = localStorage.getItem(this.storage);
      if (savedString) {
        try {
          const savedObject = JSON.parse(savedString);
          return savedObject;
        } catch {
          localStorage.removeItem(this.storage);
        }
      }
    }

    return false;
  }

  protected saveStorage(values: T[]): void {
    if (localStorage && localStorage instanceof Storage) {
      localStorage.setItem(this.storage, JSON.stringify(values));
    }
  }

  protected abstract getHttp(): Observable<T[]>;

  protected abstract updateHttp(state: T[]): Observable<T[]>;

  protected abstract clearHttp(): Observable<void>;

  protected wireEventStream(): Observable<T[]> {
    return this.changes$.pipe(
      /**
       * First we need to merge the current single update with the most
       * recent state so the ui can be updated immediately
       */
      withLatestFrom(this.state$),
      map(([update, oldState]) => {
        update.timestamp = (new Date()).getTime();

        let newState: T[];
        const index = oldState.findIndex(f => f.nid === update.nid);
        if (index !== -1) {
          newState = [...oldState];
          newState[index] = Object.assign(newState[index], update);
        } else {
          newState = [update, ...oldState];
        }

        // Push the state change to the user stream
        this.user$.next(newState);

        return update;
      }),

      /**
       * Get the latest state (in case it changed since we started) and
       * merge the array of changes into it
       */
      withLatestFrom(this.state$),
      map(([updates, oldState]) => {
        // This creates duplicates, but the changes should be at the end of list
        const combined = [...oldState, updates];

        // Reduce the array of duplicates down to an array w/o
        return combined.reduce((dedup: T[], item, i) => {

          // See if the item is already in the list
          const index = dedup.findIndex(f => f.nid === item.nid);

          // If it is already in the list
          if (index !== -1) {
            /**
             * Find the most recent instance from the timestamp,
             * and merge it into the other instance of the same record.
             */
            if (item.timestamp > dedup[index].timestamp) {
              dedup[index] = Object.assign(dedup[index], item);
            } else {
              dedup[index] = Object.assign(item, dedup[index]);
            }

            // Return the reduced array
            return dedup;
          } else {
            /**
             * Current item isn't already in the reduce array,
             * so just return it w/ the new item at the end
             */
            return [...dedup, item];
          }
        }, []);
      }),

      /**
       * Now that we have a merged array of the old state with the new state,
       * send it to the server to update the database
       */
      withLatestFrom(this.connection.State$, this.userService.User$),
      concatMap(([state, connected, user]) => connected ?
        this.updateHttp(state).pipe(catchError((error, caught) => of(state)))
        : of(state)),
      tap(state => this.saveStorage(state))
    ) as Observable<T[]>;
  }

  public Has$(item: T): Observable<boolean> {
    return this.state$.pipe(map(stubs => stubs.find(stub => stub.nid === item.nid && stub.status === item.status) !== undefined));
  }

  protected update(item: T): void {
    this.changes$.next(item);
  }

  public Add(item: T): void {
    item.status = true;
    this.update(item);
  }

  public Remove(item: T): void {
    item.status = false;
    this.update(item);
  }

  public Clear(): Observable<T[]> {
    return this.clearHttp().pipe(
      withLatestFrom(this.state$),
      map(([, latest]) => latest.map(item => ({...item, status: false }))),
      tap(results => this.server$.next(results))
    );
  }

  public State$(): Observable<T[]> {
    return this.state$;
  }

  public abstract Sorted$(): Observable<T[]>;

}
