<h1 class="content-search mt-4"><span class="border-start">&nbsp;</span>Search Ingredients or Conditions</h1>

<app-search-box (search)="search$.next($event)"></app-search-box>

<div class="row justify-content-end">
  <div class="col-lg-6 col-md-6 col-6">
    <a class="float-end mb-3" [routerLink]="['list', 'ingredients']">List All Ingredients<span class="d-none d-sm-inline-block">&nbsp;(A-Z)</span></a>
  </div>
  <div class="col-lg-6 col-md-6 col-6">
    <a class="float-end mb-3" [routerLink]="['list', 'conditions']">List All Conditions<span class="d-none d-sm-inline-block">&nbsp;(A-Z)</span></a>
  </div>
</div>

<div class="row pt-5">
  <div class="col-12">
    <p class="save-text">
      <a [routerLink]="['/', 'add-to-home-screen']"><img alt="icon" class="float-start icon-a2hs" src="{{ deployUrl }}assets/icons/icon-save.png"></a>
      <!-- span tag needed below to prevent Bootstrap from breaking apart the line -->
      <span><a [routerLink]="['/', 'add-to-home-screen']">Save ASCP SkinPro</a> to your phone or device so it works like an app!</span>
    </p>
  </div>
</div>

<ng-container *ngIf="ContentNodes$ | async as ContentNodes">
  <content-short class="mb-3" *ngFor="let contentnode of ContentNodes" [ContentNode]="contentnode"></content-short>
  <div *ngIf="ContentNodes && ContentNodes.length === 0 && search$.value && search$.value[0] && search$.value[0].length > 0" class="jumbotron">
    <p>No conditions or ingredients found...</p>
  </div>
</ng-container>
