import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Condition } from '../../models/condition.model';
import { ConditionService } from '../../services/condition.service';
import { GetSingle } from 'src/app/services/helpers';
import { RecentContentService } from 'src/app/services/recent-content.service';

@Component({
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent implements OnInit {

  public Condition$: Observable<Condition>;

  constructor(
    private route: ActivatedRoute,
    private conditionService: ConditionService,
    private recentService: RecentContentService) {
  }

  ngOnInit(): void {
    this.Condition$ = this.route.paramMap.pipe(
      switchMap(param =>
        GetSingle(this.conditionService.Content$, param.get('name')),
      ),
      tap(condition => this.recentService.Add({
        nid: condition.nid,
        status: true,
        type: condition.Type,
        name: condition.name,
        alias: condition.alias,
      })),
    );
  }

}
