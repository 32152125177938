import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { tokenGetter } from '../helpers/tokenGetter';
import { Environment, IEnvironment } from '../models/environment';
import { IUser, JWTSSO, PACJwt, User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user$: Subject<User> = new ReplaySubject<User>(1);

  public get User$(): Observable<User> {
    return this.user$.asObservable();
  }

  constructor(
    @Inject(Environment) private environment: IEnvironment,
    private router: Router,
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService) {

      this.CheckActiveSession();

  }

  private CheckActiveSession(): void {
    this.httpClient.get('/api/individual').subscribe((response: any) => {
      let token = response?.JWT;

      if(token) {
        let base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let jwtObject = JSON.parse(jsonPayload);

        let user = new User(jwtObject as JWTSSO);
        user.jwt = token;
        this.PingApi(user).subscribe(user => {
          this.user$.next(user);
          console.log(user);
        });
      }
    });
  }

  public Login(Username: string, Password: string): Observable<IUser> {
    return this.httpClient
      .post<PACJwt>(this.environment.netforum + 'JWT',
        { UserName: Username, Password: Password },
        {
          params: {
            Site: 'ASCP'
          },
        }
      )
      .pipe(
        catchError((err) => this.HandleLoginError(err)),
        map(response => {
          if (response instanceof Error) {
            throw response;
          }
          const user = new User(response);
          return user;
        }),
        switchMap(user => this.PingApi(user)),
        tap(user => {
          if (user instanceof User) {
            this.user$.next(user);
          }
        }),
      );
  }

  private PingApi(user: User): Observable<User> {
    return this.httpClient.get(this.environment.api + 'user',
      {
        headers: {
          "Authorization": "Bearer " + user.jwt
        }
      }).pipe(
      catchError((err) => this.pingHandleError(err)),
      map(response => {
        if (response instanceof Error) {
          return null;
        }
        return user;
      }),
      take(1),
    );
  }

  private pingHandleError(err) {
    if (err.status === 403) {
      this.router.navigate(['/access-denied']);
    }
    return this.HandleLoginError(err);
  }

  private HandleLoginError(error: HttpErrorResponse): Observable<Error | User | null> {
    if (error.status === 403) {
      if (error.error && 'message' in error.error) {
        
        if (error.error.message === 'Login Required') {
          return of(new Error('Login Required'));
        }
        if (error.error.message === 'LoginByEmail Failed') {
          return of(new Error('Sorry, unrecognized email address or password.'));
        }
        if (error.error.message.includes('permission is required')) {
          return of(new Error('Access to this resource is not included in your membership'));
        }

        return of(new Error(error.error.message));
      }
    }

    if (error.error && 'message' in error.error) {
      if (error.error.message === 'Failed to aquire read lock') {
        return of(new Error(error.error.message));
      }

      return of(new Error('Unknown error: ' + error.error.message));
    }

    return of(new Error(`Unknown error: ${error.status} ${error.statusText} ${error.error}`));
  }

  public authenticateFromToken(): void {
    try {
      const token = tokenGetter();
      if (!token) {
        this.user$.next(null);
        return;
      }

      const decodedToken = this.jwtHelper.decodeToken(token);
      const isExpired = this.jwtHelper.isTokenExpired(token);

      if (!isExpired) {
        if ('pac' in decodedToken) {
          const user = new User(decodedToken);
          user.jwt = token;
          user.fromCache = true;
          this.PingApi(user).subscribe(user => this.user$.next(user));
          return ;
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.user$.next(null);
  }
}
