<div class="container pt-3">
  <div class="row">
    <ng-container *ngIf="Condition$ | async as Condition">
      <div class="col-xs-12 col-lg-5 left-panel">
        <app-title [Content]="Condition"></app-title>
        <app-definition Definition="{{ Condition.definition }}"></app-definition>
        <app-image [Content]="Condition"></app-image>
      </div>
      <div class="col-xs-12 col-lg-7">
        <app-tabs Summary="{{ Condition.summary }}" Overview="{{ Condition.overview }}" Considerations="{{ Condition.considerations }}"
          Contraindications="{{ Condition.contraindications }}"></app-tabs>
        <app-accordion [Related_Conditions]="Condition.related_conditions"
          [Related_Ingredients]="Condition.related_ingredients"
          Research_and_Resources="{{ Condition.research_and_resources }}"></app-accordion>
      </div>
    </ng-container>
  </div>
</div>
