export interface IUser {
  cst_recno: number | null;
  ind_first_name: string | null;
  ind_last_name: string | null;
  ind_badge_name: string | null;
  eml_address: string | null;
  jwt: string | null;
  fromCache: boolean;
  cacheAge?: Date;

  IsLoggedIn(): boolean;
}

export interface PACJwt {
  pac: {
    cst_recno: number;
  };

  given_name: string;
  family_name: string;
  nickname: string;
  email: string;

  iat: number;
  exp: number;
  nbf: number;
  jti?: string;
}

export interface JWTSSO {
  ObjectName: 'Individual';
  CurrentKey: string;
  Columns: {
    cst_recno: number;
    ind_first_name: string;
    ind_last_name: string;
    ind_badge_name: string;
    eml_address: string;
    update: number;
  }
}

export class User implements IUser {

  public cst_recno: number | null = null;
  public ind_first_name: string | null = null;
  public ind_last_name: string | null = null;
  public ind_badge_name: string | null = null;
  public eml_address: string | null = null;
  public jwt: string | null = null;

  public fromCache: boolean = false;
  public cacheAge?: Date = new Date();

  public IsLoggedIn(): boolean {
    return this.cst_recno !== null && this.cst_recno !== 0;
  }

  constructor(jwt: PACJwt | JWTSSO | User) {
    try {
      if ('pac' in jwt) {
        this.cst_recno = jwt.pac.cst_recno;
        this.ind_badge_name = jwt.nickname;
        this.ind_first_name = jwt.given_name;
        this.ind_last_name= jwt.family_name;
        this.eml_address = jwt.email;
        this.cacheAge = new Date(jwt.nbf * 1000);
      } else if ('ObjectName' in jwt) {
        this.cst_recno = jwt.Columns.cst_recno;
        this.ind_badge_name = jwt.Columns.ind_badge_name;
        this.ind_first_name = jwt.Columns.ind_first_name;
        this.ind_last_name= jwt.Columns.ind_last_name;
        this.eml_address = jwt.Columns.eml_address;
        this.cacheAge = new Date(jwt.Columns.update * 1000);
      }
    } catch (e) {
      this.cst_recno = 0;
    }
  }
}
