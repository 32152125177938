<div class="container pt-3">
  <div class="row">
    <ng-container *ngIf="Ingredient$ | async as Ingredient">
      <div class="col-xs-12 col-lg-5 left-panel">
        <app-title [Content]="Ingredient"></app-title>
        <app-definition Definition="{{ Ingredient.definition }}"></app-definition>
      </div>
      <div class="col-xs-12 col-lg-7">
        <app-tabs Summary="{{ Ingredient.summary }}" Overview="{{ Ingredient.overview }}" Considerations="{{ Ingredient.considerations }}"
          Contraindications="{{ Ingredient.contraindications }}"></app-tabs>
        <app-accordion [Related_Conditions]="Ingredient.related_conditions"
          [Related_Ingredients]="Ingredient.related_ingredients"
          Research_and_Resources="{{ Ingredient.research_and_resources }}"></app-accordion>
      </div>
    </ng-container>
  </div>
</div>
