<div class="brand_wrap d-md-none d-block me-3" ngClass="col-1 col-md-1 col-lg-1 navbar-login-route-logo">
  <a class="icon-search" routerLink="search">
    <img class="image" src="{{ deployUrl }}assets/images/icon_search.png">
  </a>
</div>

<div #navSearch class="brand_wrap d-none d-md-block" [ngClass]="{'col-12 col-sm-12 SearchIsOpen' : searchOpen, 'col-12 col-sm-12 pe-0' : !searchOpen}">
  <ng-container>
    <form (transitionend)="onTransitionEnd($event)" class="my-0 me-0 my-lg-0 justify-content-end">
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary d-md-none outline-none right" type="button" (click)="searchOpen = !searchOpen">
            <fa-icon [icon]="faSearch">Search</fa-icon>
          </button>
          <em class="input-group-text d-none d-md-block icon-search">
            <fa-icon [icon]="faSearch">Search</fa-icon>
          </em>
        </div>
        <input name="search" #search [ngModel]="search$ | async" (ngModelChange)="search$.next($event.trim().toLowerCase())" [typeahead]="ContentNodes$" typeaheadOptionField="name" [isAnimated]="true" [adaptivePosition]="true" [typeaheadItemTemplate]="searchItem" (typeaheadOnSelect)="onSelect($event)" class="form-control mr-sm-2" type="search" placeholder="Search for an ingredient or condition" aria-label="Search" (blur)="close()">
      </div>
    </form>
  </ng-container>
</div>

<ng-template #searchItem let-item="item">
  <h6>{{ item.name }}</h6>
</ng-template>