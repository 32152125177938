import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion/accordion-group.component';
import { SubSink } from 'subsink';

import { IRelatedContent } from 'src/app/models/content.interface';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AppAccordionComponent implements OnInit {

  public slideOpen = true;
  public onePanelAtATime = true;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  private subs = new SubSink();

  @Input()
  public Related_Conditions: IRelatedContent[] = [];

  @Input()
  public Related_Ingredients: IRelatedContent[] = [];

  @Input()
  public Research_and_Resources: string = '';

  @ViewChild('conditions', { static: false }) conditions: AccordionPanelComponent;
  
  @ViewChild('ingredients', { static: false }) ingredients: AccordionPanelComponent;

  @ViewChild(AccordionComponent) public accordion: AccordionComponent;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subs = new SubSink();

    this.subs.sink = this.route.paramMap.subscribe(param => {
      subs.unsubscribe();

      if (this.accordion) {
        this.accordion.closeOtherPanels(null);
      }
    });
  }

}
