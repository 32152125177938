<div class="container dropdown-page">
  <h1>About ASCP</h1>

  <p>Associated Skin Care Professionals (ASCP) is the nation's largest association for skin care professionals and your ONLY all-inclusive source for professional liability insurance, education, community, and career support.</p>
  
  <p>For estheticians at every stage of the journey who are passionate about skin care, ASCP is your essential partner. ASCP empowers you to reach your highest potential by providing innovative business solutions, unmatched quality education, vital professional liability insurance, and a dedicated team who cares about our members' success and is always willing to go the extra mile for our members.</p>
  
  <p>ASCP's members include estheticians, students, instructors, and educators across the United States, and it is the only organization providing estheticians with industry-specific benefits in addition to comprehensive liability insurance coverage.</p>
  
  <h2>Connect With Us</h2>
  <p>Phone: <a href="tel:8007890411">800-789-0411</a><br />
    Website: <a href="//www.ascpskincare.com" target="_blank">www.ascpskincare.com</a><br />
    Email: <a href="mailto:getconnected@ascpskincare.com">getconnected@ascpskincare.com</a><br />
    Facebook: <a href="//www.facebook.com/ASCPskincare" target="_blank">www.facebook.com/ASCPskincare</a><br />
    Instagram: <a href="//www.instagram.com/ascpskincare" target="_blank">www.instagram.com/ascpskincare</a>
  </p>
</div>