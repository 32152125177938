import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';
import { filter, map, share } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private State$ = this.router.events.pipe(
    filter(event => event instanceof ResolveEnd),
    map((event: ResolveEnd) => event.url),
    map(url => url.startsWith('/login')),
    share(),
  );

  @Input()
  public hideSearch: boolean = false;

  @Input()
  public hideMenu: boolean = true;

  @HostBinding('class')
  public htmlClass: string = 'bg-white';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
