import { Component, OnInit, Input } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faHistory, faHome, faUndo } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class MenuDropdownComponent implements OnInit {

  @Input()
  public ButtonClass: Array<string>;

  @Input()
  public ButtonText: string = '';

  @Input()
  public Placement: 'top' | 'bottom' = 'bottom';

  public faBars = faBars;
  public faBookmarkRegular = faBookmarkRegular;
  public faHistory = faHistory;
  public faHome = faHome;
  public faUndo = faUndo;

  constructor() { }

  ngOnInit(): void {
  }

}
