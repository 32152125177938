import { Component, ElementRef, OnInit, Inject, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Condition } from 'src/app/models/condition.model';
import { AllContent, TitleMatch } from '../../services/helpers';
import { ConditionService} from '../../services/condition.service';
import { IngredientService } from '../../services/ingredient.service';

import { UserService } from '../../services/user.service';
import { User } from 'src/app/models/user.model';

import { DEPLOY_URL } from 'src/app/tokens/deploy-url';

@Component({
  selector: 'app-nav-search-bar',
  templateUrl: './nav-search-bar.component.html',
  styleUrls: ['./nav-search-bar.component.scss']
})
export class NavSearchBarComponent implements OnInit {

  public ContentNodes$: Observable<AllContent[]>;
  public faSearch = faSearch;
  public search$: Subject<string>;
  public searchOpen: boolean = false;
  public searchIsSet: boolean = false;

  public showAppMenuDropdown$: Observable<boolean> = this.userService.User$.pipe(
    filter(user => user instanceof User),
    map(user => user.IsLoggedIn()),
    shareReplay(1)
  );

  public showSearchbar$: Observable<boolean> = combineLatest([this.showAppMenuDropdown$, this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))]).pipe(
    map(([isLoggedIn, routeEvent]) => {
      return isLoggedIn && !(routeEvent.url == '/search' || routeEvent.url == '/login' || routeEvent.url == '/access-denied');
    })
  );

  @ViewChild('search', { read: ElementRef })
  public search: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(DEPLOY_URL) public deployUrl: string, 
    public router: Router, 
    private conditionService: ConditionService, 
    private ingredientService: IngredientService, 
    private userService: UserService,
    private elRef: ElementRef) {
    this.search$ = new Subject();
  }

  ngOnInit(): void {
    this.searchIsSet = false;
    this.ContentNodes$ = combineLatest([this.search$, this.conditionService.Content$, this.ingredientService.Content$]).pipe(
      map(([search_term, conditions, ingredients]) => {
        if (!search_term) {
          return [];
        }

        const nodes = [...conditions, ...ingredients];
        return nodes.filter(node => {
          let match = TitleMatch(node, search_term);
          return match;
        });
      })
    );
  }

  public onTransitionEnd(event: TransitionEvent): void {
    if (event.propertyName === 'width') {
      if (this.elRef.nativeElement.parentElement.classList.contains('searchOpen')) {
        this.searchIsSet = true;
        this.search.nativeElement.focus();
      } else {
        this.search.nativeElement.blur();
        this.searchIsSet = false;
      }
    }
  }

  public onSelect(event: TypeaheadMatch): void {
    this.search.nativeElement.blur();
    this.search$.next(null);
    let contentType = event.item instanceof Condition ? 'conditions' : 'ingredients';
    this.router.navigate([`/${contentType}`, event.item.alias]);
  }

  public close(): void {
    timer(250).subscribe(() => {
      this.searchOpen = false;
    });
  }

}
