
<nav class="navbar fixed-bottom navbar-light">
  <ng-container *ngIf="!hideMenu">
    <button class="btn btn-lg" type="button" routerLink="/">
      <fa-icon class="h4" [icon]="faHome"></fa-icon>
      <span class="btn_text">Home</span>
    </button>
    <button class="btn btn-lg" type="button" routerLink="/favorite">
      <fa-icon class="h4" [icon]="faBookmarkSolid"></fa-icon>
      <span class="btn_text">Saved</span>
    </button>
    <button class="btn btn-lg" type="button" routerLink="/recent">
      <fa-icon class="h4" [icon]="faUndo"></fa-icon>
      <span class="btn_text">Recent</span>
    </button>
  </ng-container>

  <button class="btn btn-lg" type="button" routerLink="/using-skinpro">
    <fa-icon class="h4" [icon]="faQuestion"></fa-icon>
    <span class="btn_text">FAQs</span>
  </button>

  <app-menu-dropdown *ngIf="!hideMenu" Placement="top" [ButtonClass]="['btn-bottom-nav', 'btn-lg']" ButtonText="Menu"></app-menu-dropdown>
</nav>
  
<div class="container-lg">
  <footer class="text-center fixed-bottom mx-0">
    <a href="/privacy-policy" target="_blank">Privacy Policy</a>
    | <a href="/copyright-policy" target="_blank">Copyright</a>
    | <a href="/terms-use" target="_blank">Terms of Use</a>
    | All Rights Reserved
  </footer>
</div>
  