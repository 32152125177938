import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  private loader$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private userService: UserService,
    private router: Router) {
  }

  public get Loader$(): Observable<boolean> {
    return this.loader$;
  }

  public canActivate(): Observable<boolean> {

    this.loader$.next(true);

    return this.userService.User$.pipe(
      map(user => {
        this.loader$.next(false);

        if (user === null || user.IsLoggedIn() === false) {
          this.router.navigate(['login']);
          return false;
        }

        return true;
      })
    );
  }

}
