<div class="container dropdown-page">
  <h1>About Mark Lees</h1>

  <div class="row">
    <div class="col-12 col-md-4">
      <img alt="headshot of man" class="float-start headshot" src="{{ deployUrl }}assets/images/mark_lees.jpg">
    </div>
    <div class="col-12 col-md-8">
      <p>Mark Lees, PhD, MS, is an award-winning speaker, master clinical skin therapist, and developer of Mark Lees Skin Care, which specializes in products for acne-prone, sensitive, and aging skin. He is also author of <em>Skin Care: Beyond the Basics</em>, the <em>Skin Care Answer Book</em>, and his latest book, <em>Clearing Concepts: A Guide to Acne Treatment</em>. He writes for professional journals, including a column for <em>ASCP Skin Deep</em> magazine, and has authored chapters for two medical/dental school texts, and the cosmetic chemistry “bible”&mdash;<em>Harry's Cosmeticology Volume 3</em>. He is a licensed cosmetologist (Florida and Washington), a licensed master esthetician (Washington), and holds a CIDESCO International Diploma.</p>
      <p>Lees is the former chairman of EstheticsAmerica&mdash;the esthetics education division of the National Cosmetology Association (NCA)&mdash;and has served as a CIDESCO International Examiner. He has also served on the national board of directors of the NCA, is the former chairman of the board of the Esthetics Manufacturers and Distributors Alliance, and is a member of the Society of Cosmetic Chemists.</p>
      <p>His professional awards include Esthetician of the Year from <em>American Salon</em> magazine, the <em>Les Nouvelles Esthétiques</em> Crystal Award, the <em>Dermascope</em> Legends Award, and the Esthetics International Humanitarian Award from the Southern Spa and Salon Conference. He was also inducted into the National Cosmetology Association's Hall of Renown. In addition to his practice and product development company, Lees has consulted for major pharmaceutical companies, universities, and numerous state boards of cosmetology.</p>
      <p>For more information on books by Mark Lees, or to make a purchase, visit the <a href="https://shop.marklees.com/Books-and-DVDs_c7.htm" target="_blank">Mark Lees Skin Care Store</a>.</p>
    </div>
  </div>

</div>