import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService implements OnDestroy {

  private connectionMonitor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>((navigator && 'onLine' in navigator) ? navigator.onLine : true);
  private onOffline = ((event: any) => this.connectionMonitor.next(false));
  private onOnline = ((event: any) => this.connectionMonitor.next(true));

  public State$: Observable<boolean> = this.connectionMonitor.asObservable();

  constructor() {
    window.addEventListener('offline', this.onOffline);
    window.addEventListener('online', this.onOnline);
  }

  public ngOnDestroy(): void {
    this.connectionMonitor.complete();
    window.removeEventListener('offline', this.onOffline);
    window.removeEventListener('online', this.onOnline);
  }

  public monitor(): Observable<boolean> {
    return this.connectionMonitor;
  }

  public Offline(): Observable<void> {
    return this.connectionMonitor.pipe(filter(status => !status), map(status => {}));
  }

  public Online(): Observable<void> {
    return this.connectionMonitor.pipe(filter(status => status), map(status => {}));
  }

}
