<div id="tabs" #tabs>

  <ul class="nav nav-pills">
    <li class="nav-item">
      <a class="nav-link" href="#overview" data-toggle="tab" aria-selected="true"
        [ngClass]="{ 'active' : activeTab === 'overview'}" (click)="openTab('overview', $event)">Overview</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#considerations" data-toggle="tab" 
        [ngClass]="{ 'active' : activeTab === 'considerations'}" (click)="openTab('considerations', $event)">Considerations</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#contraindications" data-toggle="tab" 
        [ngClass]="{ 'active' : activeTab === 'contraindications'}" (click)="openTab('contraindications', $event)">Contraindications</a>
    </li>
  </ul>

  <div class="tab-content">
    <div class="tab-pane pt-3" [ngClass]="{ 'active' : activeTab === 'overview'}">
      <div *ngIf="Summary; else noSummary">
        <div id="summary" [innerHTML]="Summary"></div>
        <div *ngIf="isCollapsed" class="checkbox read-more">
          <label><input type="checkbox" [(ngModel)]="isCollapsed">(read more)</label>
        </div>
        <div id="overview" [innerHTML]="Overview" [collapse]="isCollapsed" [isAnimated]="true"></div>
        <div *ngIf="!isCollapsed" class="checkbox read-less">
          <label><input type="checkbox" [(ngModel)]="isCollapsed">(read less)</label>
       </div>
      </div>
      <ng-template #noSummary>
        <div id="overview" [innerHTML]="Overview"></div>
      </ng-template>
    </div>
    <div class="tab-pane" id="considerations" [ngClass]="{ 'active' : activeTab === 'considerations'}" [innerHTML]="Considerations"></div>
    <div class="tab-pane" id="contraindications" [ngClass]="{ 'active' : activeTab === 'contraindications'}" [innerHTML]="Contraindications"></div>
  </div>

</div>