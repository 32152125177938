<accordion #accordion [isAnimated]="slideOpen" [closeOthers]="onePanelAtATime" class="content-accordion">
  <accordion-group #conditions *ngIf="Related_Conditions.length > 0" is-open="open">
    <button class="btn btn-block clearfix text-left" accordion-heading type="button">
      <fa-icon [icon]="conditions.isOpen ? faChevronUp : faChevronDown"></fa-icon>Related Conditions
    </button>
    <ul>
      <li *ngFor="let item of Related_Conditions"><a [routerLink]="['/conditions/', item.alias]">{{ item.name }}</a></li>
    </ul>
  </accordion-group>
  <accordion-group class="expand" #ingredients *ngIf="Related_Ingredients.length > 0">
    <button class="btn btn-block clearfix text-left" accordion-heading type="button">
      <fa-icon [icon]="ingredients.isOpen ? faChevronUp : faChevronDown"></fa-icon>Related Ingredients
    </button>
    <ul>
      <li *ngFor="let item of Related_Ingredients"><a [routerLink]="['/ingredients/', item.alias]">{{ item.name }}</a></li>
    </ul>
  </accordion-group>
  <accordion-group class="expand" #research *ngIf="Research_and_Resources != ''">
    <button class="btn btn-block clearfix text-left" accordion-heading type="button">
      <fa-icon [icon]="research.isOpen ? faChevronUp : faChevronDown"></fa-icon>Research and Resources
    </button>
    <div [innerHTML]="Research_and_Resources"></div>
  </accordion-group>
</accordion>