import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { ISavedContent } from '../../../services/saved-content.service';
import { FavoriteContentService } from 'src/app/services/favorite-content.service';
import { RecentContentService } from 'src/app/services/recent-content.service';

@Component({
  templateUrl: './content-saved.component.html',
  styleUrls: ['./content-saved.component.scss']
})
export class ContentSavedComponent implements OnInit {

  private sink: SubSink = new SubSink();

  public faTimes = faTimes;
  public Title$: Observable<'favorite' | 'recent'> = this.route.data.pipe(map(data => data.title));
  public Type$: Observable<'favorite' | 'recent'> = this.route.data.pipe(map(data => data.type));

  public Content$: Observable<ISavedContent[]> = this.Type$.pipe(
    switchMap<'favorite' | 'recent', Observable<ISavedContent[]>>(type => this[type].Sorted$()),
    map(content => content.filter(item => item.status)),
    withLatestFrom(this.Type$),
    map(([content, type]) => type == 'recent' ? content.slice(0, 20) : content),
  );

  constructor(
    private route: ActivatedRoute,
    private recent: RecentContentService,
    private favorite: FavoriteContentService) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  public Remove(item: ISavedContent, type: 'favorite' | 'recent'): void {
    this[type].Remove(item);
  }

  public Clear(type: 'favorite' | 'recent'): void {
    this[type].Clear().subscribe();
  }

  public trackBy(index: number, item: ISavedContent): number {
    return item.nid;
  }

}
