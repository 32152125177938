import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Inject, Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { Environment, IEnvironment } from '../models/environment';
import { ConnectionService } from './connection.service';
import { ISavedContent, SavedContentService } from './saved-content.service';
import { UserService } from './user.service';

export interface FavoriteContent extends ISavedContent {
}

@Injectable({
  providedIn: 'root'
})
export class FavoriteContentService extends SavedContentService<FavoriteContent> implements OnDestroy {

  private endpoint = this.environment.api + 'user/favorite';
  protected bufferTime: number = 1000;

  constructor(
    @Inject(Environment) private environment: IEnvironment,
    private http: HttpClient,
    appRef: ApplicationRef,
    userService: UserService,
    connection: ConnectionService) {
    super('FavoriteContent', appRef, userService, connection);

    // Refresh the data from the server on first time
    this.getHttp().subscribe(state => this.server$.next(state));

    // Get things wired up
    combineLatest([this.appRef.isStable, this.connection.State$]).pipe(
      filter(([stable, connected]) => stable && connected),
      take(1),
      switchMap(() => this.wireEventStream()),
    ).subscribe(state => {
      this.server$.next(state);
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected getHttp(): Observable<FavoriteContent[]> {
    return combineLatest([this.userService.User$, this.connection.State$]).pipe(
      filter(([, connected]) => connected),
      switchMap(() => this.http.get<FavoriteContent[]>(this.endpoint,
        {
          headers: {
            "Authorization": "Bearer " + this.token
          }
        })),
    );
  }

  protected updateHttp(state: any[]): Observable<FavoriteContent[]> {
    return this.http.patch<FavoriteContent[]>(this.endpoint, state,
      {
        headers: {
          "Authorization": "Bearer " + this.token
        }
      });
  }

  protected clearHttp(): Observable<void> {
    return this.http.delete<void>(this.endpoint,
      {
        headers: {
          "Authorization": "Bearer " + this.token
        }
      });
  }

  public Sorted$(): Observable<FavoriteContent[]> {
    return this.state$.pipe(
      map(state => state.sort((a, b) => a.name.localeCompare(b.name))),
    );
  }
}
