<app-header [ngClass]="HeaderClass$ | async" [hideMenu]="hideMenu" [hideSearch]="hideSearch"></app-header>

<div [@routeAnimations]="o && o.activatedRouteData && o.activatedRouteData['animation']">
  <router-outlet #o="outlet">
    <div *ngIf="guardLoader$ | async" class="container-lg">
      <main>
        <div class="jumbotron mt-4">
          <div class="d-flex align-items-center justify-content-center">
            <div class="spinner-border" role="status" aria-hidden="true"></div>
            &nbsp;
            <strong>Loading...</strong>
          </div>
        </div>
      </main>
    </div>
  </router-outlet>
</div>

<app-nav-bottom [hideMenu]="hideMenu"></app-nav-bottom>